/**
 * 根据 skc 获取加车数量
 * @param {String[]} skcList 商品 skc
 * @returns {Promise<Object>} { [skc]: { skc, quantity, list: [{ id, skc, sku, quantity }] } }
 */
export const getCartNumBySkc = async (skcList, config) => {
  if (typeof window === 'undefined' || !window._gb_cart_tag_tips_ || !skcList?.length) return {}

  await window._gb_cart_tag_tips_.initPromiseActuator.promise

  if (
    config?.updateCartData && // 需要更新缓存数据
    window._gb_cart_tag_tips_.updatePromiseActuator?.state !== 'pending' // 没有进行中的更新
  ) {
    // 更新数据
    window._gb_cart_tag_tips_.getApi({}, { onlyAjax: true })
  }

  // 在更新了
  if (window._gb_cart_tag_tips_.updatePromiseActuator?.state === 'pending') {
    // 等待更新完成
    await window._gb_cart_tag_tips_.updatePromiseActuator.promise
  }

  const cartList = window._gb_cart_tag_tips_.cache4cartList.get()

  if (!cartList?.length) return {}

  const skcMap = cartList.reduce((pre, cur) => {
    if (!pre[cur.goods_sn]) {
      pre[cur.goods_sn] = {
        skc: cur.goods_sn,
        quantity: 0,
        list: []
      }
    }

    pre[cur.goods_sn].quantity += cur.quantity
    pre[cur.goods_sn].list.push({
      id: cur.id,
      skc: cur.goods_sn,
      sku: cur.sku_code,
      quantity: cur.quantity,
    })

    return {
      ...pre,
    }
  }, {})

  const res = skcList.reduce((pre, cur) => {
    if (skcMap[cur]) {
      pre[cur] = skcMap[cur]
    }
    return pre
  }, {})

  return res
}
