<template>
  <div>
    <FashionStore
      v-if="isFashionStore"
      :language="language"
      :item="item"
      :config="productItemConfig"
      :index="index"
      :constant-data="constantData"
      :analysis-data="{}"
      :urlExtendParams="getUrlExtendParams(item, index)"
    />
    <template v-else>
      <NewProductItem 
        :ref="`goodsItem_${index}`"
        :key="`${item.goods_id}-${index}`"
        :config="productItemConfig"
        :item="item"
        :index="index"
        :cur-operate-index="feedbackIndex"
        :lcp="landing && (index === 0 || item.lazyClose)"
        :language="language"
        :constant-data="constantData"
        :report-metrics="reportMetrics"
        @mounted="$emit('cardMounted', $event)"
        @openQuickAdd="handleClickAddCart"
        @clickCheckout="handleClickCheckout"
        @clickItem="handleClickItem"
        @openBuyBoxDrawer="openBuyBoxDrawer"
        @longClick="$emit('longClick', $event)"
        @clickSearchFilter="$emit('clickSearchFilter', $event)"
      >
        <CardRecommend 
          v-if="!locals.IS_RW && useFrom !== 'RecommendDrawer'"
          ref="CardRecommend"
          :slot="slotName"
          :constant-data="constantData"
          :hasHandleFilterBar="hasHandleFilterBar"
          :language="language"
          :list-abt-result="listAbtResult"
          :feedback-rec-ccc-config="feedbackRecCccConfig"
          :item="item"
          :index="index"
          :scenes="scenes"
          :select-id="selectId"
          :cat-id="catId"
          :img-ratio="imgRatio"
          :shein-club-info="sheinClubInfo"
          @openQuickAdd="$emit('openQuickAdd', $event)"
          @operateInRecPopup="$emit('operateInRecPopup')"
          @refreshAnalysisData="$emit('refreshAnalysisData')"
          @recPopupMounted="$emit('recPopupMounted')"
          @changeSlot="changeSlot"
        />
      </NewProductItem>
    </template>
  </div>
</template>

<script>
// components
import NewProductItem from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import FashionStore from 'public/src/pages/components/product/carrier_ui/FashionStore/index.vue'

export default {
  name: 'ProductListItem',
  components: {
    NewProductItem,
    FashionStore,
    CardRecommend: () => import(/* webpackChunkName: "plv2_CardRecommend" */'./CardRecommend/CardRecommend.vue'), // 反馈弹窗上下文组件
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: () => ({})
    },
    productItemConfig: {
      type: Object,
      default: () => ({})
    },
    feedbackIndex: {
      type: Number,
      default: 0
    },
    landing: {
      type: Boolean,
      default: false
    },
    language: {
      type: Object,
      default: () => ({})
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    locals: {
      type: Object,
      default: () => ({})
    },
    cateCoupon: {
      type: Object,
      default: () => ({
        Data: {},
        Abt: {}
      })
    },
    listAbtResult: {
      type: Object,
      default: () => ({})
    },
    feedbackRecCccConfig: {
      type: Object,
      default: () => ({})
    },
    scenes: {
      type: String,
      default: ''
    },
    // 只能一级一级传下来，原因是，外层container不同，无法确定storemodulename, 例如shein-picks, 针对BUG CTD-13743 修复 （sheinClubInfo）暂时先不处理
    // catInfo: {
    //   type: Object,
    //   default: () => ({})
    // },
    // 需要它来处理反馈弹窗的会员价格问题,业务层从上带下来
    sheinClubInfo: {
      type: Object,
      default: () => ({})
    },
    // 实时反馈和快加车会用到
    useFrom: {
      type: String,
      default: ''
    },
    reportMetrics: {
      type: Object,
      default: () => ({}),
    },
    // 是否存在筛选
    hasHandleFilterBar: {
      type: Boolean,
      default: false
    },
    // 当前页面的类目ID
    catId: {
      type: [Number, String],
      default: ''
    },
    isFashionStore: {
      type: Boolean,
      default: false
    },
    // 当前页面的选品ID
    selectId: {
      type: [Number, String],
      default: ''
    },
    analysisData: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      slotName: 'imgBottom',
      imgRatio: '',
    }
  },
  methods: {
    // hack code 解决榜单类型的动态插槽问题
    async changeSlot(name = 'imgBottom') {
      // 防止后续的触发
      if(this.slotName === name) {
        return
      }
      this.slotName = name
      await this.$nextTick()
      // 动态修改插槽后重新init组件params
      this.$refs?.CardRecommend?.initRun?.(false)
    },
    handleClickItem(payload) {
      this.imgRatio = payload?.imgRatio || '3-4'
      this.$refs?.CardRecommend?.initRun?.()
      this.$emit('clickItem', payload)
    },
    handleClickColor() {
      this.$emit('clickColor')
    },
    handleClickAddCart(event) {
      this.imgRatio = event.imgRatio || '3-4'
      this.$refs?.CardRecommend?.initRun?.()
      this.$emit('openQuickAdd', event)
    },
    handleClickCheckout(emitData) {
      this.$emit('clickCheckout', emitData)
    },
    openBuyBoxDrawer(params) {
      this.$emit('openBuyBoxDrawer', params)
    },
    getUrlExtendParams(item) {
      const { storeCode } = item.storeInfo || {}
      return `&src_module=list&src_identifier=store=${storeCode}`
    }
  }
}
</script>
