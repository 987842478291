<template>
  <div class="waterfall">
    <div
      v-for="column in columns"
      ref="column"
      :key="column.i"
      :data-index="column.i"
      class="waterfall-column"
    >
      <!-- needDynamicInsertAnimation在业务层插入完成后自动的置为false -->
      <!-- <template v-if="needDynamicInsertAnimation">
        <transition-group 
          name="list-complete"
          tag="div"
        >
          <div
            v-for="i in column.indexes"
            :key="i"
            class="waterfall-item waterfall-animation-item"
          >
            <slot
              :item="items[i]"
              :index="i"
            ></slot>
          </div>
        </transition-group>
      </template>
      <template v-else> -->
      <!-- <div
        v-for="i in column.indexes"
        :key="i"
        class="waterfall-item"
      >
        <slot
          :item="items[i]"
          :index="i"
        ></slot>
      </div> -->
      <!-- </template> -->

      <!-- <template v-if="useFrom === 'picked-info-land' || useFrom === 'picked-info-entry'">
        <div
          v-for="i in column.indexes"
          :key="items[i].goods_id || i"
          class="waterfall-item"
        >
          <slot
            :item="items[i]"
            :index="i"
          ></slot>
        </div>
      </template>
      <template v-else>
        <div
          v-for="i in column.indexes"
          :key="i"
          class="waterfall-item"
        >
          <slot
            :item="items[i]"
            :index="i"
          ></slot>
        </div>
      </template> -->
      <!-- 用goods_id做索引页面waterFall报错Duplicate keys detected: -->
      <div
        v-for="i in column.indexes"
        :key="i"
        class="waterfall-item"
      >
        <slot
          :item="items[i]"
          :index="i"
          :nutareIndex="items[i].type === 'recommend-for-you' && natureLocal[i]"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaterFall',
  props: {
    // 标识该组件是被谁引用了，主要解决cartbag 加车的问题
    useFrom: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => []
    },
    /**
     * {
     *  columns: 2,
     *  itemQuantity: 6 服务端渲染的商品数量
     * }
     */
    ssr: {
      type: Object,
      default: () => ({
        columns: 2,
        itemQuantity: 6
      })
    },
    initWaterFall: {
      type: Boolean,
      default: false
    },
    strictLeftToRight: {
      type: Boolean,
      default: false,
    },
    // 是否需要动态插入卡片出现插入动画
    needDynamicInsertAnimation: {
      type: Boolean,
      default: false,
    },
    // 当前点击的商品（需要插坑的商品）在数据数组中（products）的下标
    localIndex: {
      type: [String, Number],
      default: '',
    },
    // 每次动态插坑个数
    dynamicInsertNum: {
      type: [String, Number],
      default: 1,
    }
  },
  data() {
    return {
      columns: [],
      // heightCalc: {},
      // addColumnInfo: { column: null, height: null },
      cursor: 0,
      nextIndex: 0,
      natureLocal: {}// 记录插坑之前的位置
    }
  },
  computed: {
    itemQuantity() {
      return this.ssr?.itemQuantity || 0
    },
    count() {
      return this.ssr?.columns || 0
    },
  },
  watch: {
    itemQuantity: {
      handler(val) {
        this.cursor = val
      },
      immediate: true
    },
    items: {
      async handler(newVal, oldVal) {
        if (newVal?.length && newVal?.length !== oldVal?.length) {
          // 在切换图文导航 + 网速较慢的极端场景下, 会偶发在上一页 columns 里塞满了 index
          // 但是 initWaterFall 又没有被长时间置为 true, 导致 init 的过程被跳过了, 出现空白商品项的情况
          // 这里增加一下个判断
          if (this.initWaterFall || this.cursor >= this.items.length) {
            this.initInfo()
          }
          if (!this.columns.length) {
            this.initColumns()
          }
          this.addItem(this.nextIndex)
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    initInfo() {
      this.columns = []
      this.cursor = this.itemQuantity
    },
    initColumns() {
      if (this.count > 0) {
        const columns = this.createColumns(this.count)
        let i = 0
        for (i = 0; i < Math.min(this.itemQuantity, this.items?.length); i++) {
          columns[i % this.count].indexes.push(i)
        }
        // 修正cursor有 极端插坑数据 = itemQuantity 会跳过
        this.cursor = i
        this.columns = columns
      }
    },
    // 需要创建几列
    createColumns(count) {
      const columns = []
      for (let i = 0; i < count; i++) {
        columns.push({ i: i, indexes: [] })
      }
      return columns
    },
    async addItem(nextIndex) {
      if (typeof window === 'undefined') return

      // 瀑布流塞item完成
      if (this.cursor >= this.items.length) {
        this.$emit('addItemDone')
        return
      }

      if (!this.$refs.column) {
        await this.$nextTick()
      }

      const columnDiv = this.$refs.column
      if (!Array.isArray(columnDiv) || !columnDiv.length) {
        return
      }
      // console.log(nextIndex, 'nextIndexnextIndex', this.cursor);
      let columnIndex
      // 针对点后推动态插坑，改变瀑布流算法
      if(this.items?.length && this.items[this.cursor]?.type === 'recommend-for-you' && this.localIndex > -1) {
        // 找到当前点击商品， 把推荐商品定位到下方
        //前左侧还是右侧, 拿到下标
        let leftLocalIndexKey = this.columns[0].indexes?.findIndex(item=> item === this.localIndex)
        let rightLocalIndexKey = this.columns[1].indexes?.findIndex(item=> item === this.localIndex)
        const supplementColumnsFun = () => {
          // 插双数，不需要补高逻辑,因为一定是两边一边一个
          if(this.dynamicInsertNum == 2) {
            return
          }
          // 两侧高度有差异了，高补低（即将插入短侧则不补）
          if(this.columns[0].indexes.length - this.columns[1].indexes.length >= 2) {
            let val = this.columns[0].indexes.pop()
            this.columns[1].indexes.push(val)
            return
          }
          if(this.columns[1].indexes.length - this.columns[0].indexes.length >= 2) {
            let val = this.columns[1].indexes.pop()
            this.columns[0].indexes.push(val)
          }
        }
        // supplementColumnsFun()
        // 插两个
        if(this.dynamicInsertNum == 2) {
          if(leftLocalIndexKey > -1) {
            // 点左
            let natureLocalLeft = this.columns[0].indexes[leftLocalIndexKey + 1]
            let natureLocalRight = this.columns[1].indexes[leftLocalIndexKey]
            this.natureLocal[this.cursor] = natureLocalLeft
            this.columns[0].indexes.splice(leftLocalIndexKey + 1, 0, this.cursor++)
            this.natureLocal[this.cursor] = natureLocalRight
            this.columns[1].indexes.splice(leftLocalIndexKey, 0, this.cursor)
          }else if(rightLocalIndexKey > -1) {
            // 点右
            let natureLocalLeft = this.columns[0].indexes[rightLocalIndexKey + 1]
            let natureLocalRight = this.columns[1].indexes[rightLocalIndexKey + 1]
            this.natureLocal[this.cursor] = natureLocalLeft
            this.columns[1].indexes.splice(rightLocalIndexKey + 1, 0, this.cursor++)
            this.natureLocal[this.cursor] = natureLocalRight
            this.columns[0].indexes.splice(rightLocalIndexKey + 1, 0, this.cursor)
          }
        } 
        // 插一个
        if(this.dynamicInsertNum == 1) {
          if(leftLocalIndexKey > -1) {
            // 点左
            let natureLocalLeft = this.columns[0].indexes[leftLocalIndexKey + 1]
            this.columns[0].indexes.splice(leftLocalIndexKey + 1, 0, this.cursor)
            this.natureLocal[this.cursor] = natureLocalLeft
          }else if(rightLocalIndexKey > -1) {
            // 点右
            let natureLocalRight = this.columns[1].indexes[rightLocalIndexKey + 1]
            this.columns[1].indexes.splice(rightLocalIndexKey + 1, 0, this.cursor)
            this.natureLocal[this.cursor] = natureLocalRight
          }
        }
        supplementColumnsFun()

        let isLeft = leftLocalIndexKey > -1 
        this.nextIndex = isLeft ? 1 : 0
        this.cursor++
        await this.$nextTick()
        this.addItem(this.nextIndex)
        return
      }

      if (this.strictLeftToRight) {
        columnIndex = nextIndex
        this.nextIndex = (nextIndex + 1) % this.count
      } else {
        let noSize = true
        const target = columnDiv.reduce((prev, curr) => {
          const prevHeight = prev.getBoundingClientRect().height
          const currHeight = curr.getBoundingClientRect().height
          if (prevHeight !== 0 || currHeight !== 0) {
            noSize = false
          }
          // console.log('waterFall:', this.items.length, this.cursor, prevHeight, currHeight)
          return prevHeight < currHeight ? prev : curr
        })
        columnIndex = target.dataset.index
        // 若无高度，则各个列轮流添加项nextIndexnextIndexnextIndex
        if (noSize) {
          columnIndex = nextIndex
          this.nextIndex = (nextIndex + 1) % this.count
        }
      }

      const column = this.columns[columnIndex]
      if (this.items[this.cursor]) {
        column.indexes.push(this.cursor)
        this.cursor++
        await this.$nextTick()
        this.addItem(this.nextIndex)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.waterfall {
  .flexbox();
  .space-between();
  align-items: flex-start;
}
.waterfall-column {
  .flexbox();
  flex-direction: column;
}

// .waterfall-animation-item {
//   transition: all 8s;
// }
/* stylelint-disable-next-line selector-class-pattern */
// .list-complete-enter, .list-complete-leave-to {
//   opacity: 0;
//   transform: translateY(var(--nums), -180px);
// }
/* stylelint-disable-next-line selector-class-pattern */
// .list-complete-leave-active {
//   position: absolute;
// }
</style>
