<template>
  <div>
    <div
      v-if="mergedVisible"
      class="agelimit-dialog-loading"
    >
    </div>
    <s-dialog
      v-if="isCompMounted"
      :visible="mergedVisible"
      :show-close="true"
      :bg-filter="true"
      :append-to-body="appendToBody"
      @close-from-icon="onCloseFormIcon"
    >
      <template slot="title">
        {{ title }}
      </template>
      <div class="agelimit-dialog-content">
        {{ content }}
      </div>
      <template slot="footer">
        <s-button-group width="100%">
          <s-button-group-item @click="onOk">
            <span class="agelimit-dialog-btntext">{{ okText }}</span>
          </s-button-group-item>
          <s-button-group-item @click="onCancel">
            <span class="agelimit-dialog-btntext">{{ cancelText }}</span>
          </s-button-group-item>
        </s-button-group>
      </template>
    </s-dialog>
  </div>
</template>

<script>
/**
 * @file 未满十八岁弹窗提示
 */

// 弹窗被商列、商详、搜索引用，上层组件可能没导入对应的组件库组件
import { Dialog as SDialog, ButtonGroup as SButtonGroup, ButtonGroupItem as SButtonGroupItem } from '@shein/sui-mobile'
import { updateAdultProduct } from './util'

const { langPath } = gbCommonInfo

export default {
  name: 'AgeLimitDialog',
  components: {
    SDialog,
    SButtonGroup,
    SButtonGroupItem,
  },
  props: {
    isSearchResultPage: {
      type: Boolean,
      default: false,
    },
    storeCode: {
      type: String,
      default: '',
    },
    disableScroll: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    okText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      scrollTop: 0,
      isOkClicked: false,
      isCompActive: true,
      isCompMounted: false,
    }
  },
  computed: {
    mergedVisible () {
      if (this.isOkClicked) {
        return false
      }
      return this.visible && this.isCompActive
    },
  },
  watch: {
    mergedVisible: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.$emit('expose')
          this.stopScroll()
        } else {
          this.canScroll()
        }
      },
    },
  },
  mounted () {
    this.isCompMounted = true
  },
  activated () {
    this.isCompActive = true
  },
  deactivated () {
    this.isCompActive = false
  },
  beforeDestroy () {
    this.canScroll()
  },
  methods: {
    stopScroll () {
      if (typeof window === 'undefined' || !this.disableScroll) {
        return
      }
      const _scrollTop = document.scrollingElement.scrollTop
      this.scrollTop = _scrollTop
      document.body.classList.add('agelimit-dialog-disablescroll')
      document.body.style.top = -_scrollTop + 'px'
    },
    canScroll () {
      if (typeof window === 'undefined' || !this.disableScroll) {
        return
      }
      document.body.classList.remove('agelimit-dialog-disablescroll')
      document.scrollingElement.scrollTop = this.scrollTop
    },
    onOk () {
      this.$emit('ok')
      updateAdultProduct()
      this.isOkClicked = true
    },
    onCancel () {
      this.$emit('cancel')
      this.goBack()
    },
    onCloseFormIcon () {
      this.$emit('close-from-icon')
      this.goBack()
    },
    goBack () {
      if (this.isSearchResultPage) {
        if (this.storeCode) {
          location.href = `${langPath}/store/home?store_code=${this.storeCode}`
        } else {
          location.href = langPath + '/'
        }
        return
      }
      if (history.length > 1) {
        this.$router.back()
        return
      }
      location.href = langPath + '/'
    },
  },
}
</script>

<style lang="less">
/* postcss-shein:ignore */
.agelimit-dialog-disablescroll {
  overflow: hidden;
  height: 100%;
}
</style>

<style lang="less" scoped>
.agelimit-dialog-loading {
  /* stylelint-disable */
  z-index: 100000;
  transform: translate3d(0px, 0px, 100000px);
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.agelimit-dialog-content {
    font-size: 14px;
    line-height: 17px;
    margin-top: 0.3rem;
}
.agelimit-dialog-btntext {
    font-size: 12px;
    line-height: 14px;
    & when (@IS_RW) {
        color: #fc4070;
    }
}
</style>
