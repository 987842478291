<template>
  <div
    ref="productList"
    class="product-list-v2__list j-da-event-box j-product-list-info actived-newproductcard"
    code="productList"
    :data-tag_ids="catInfo.tag_ids"
    :data-cat="catInfo.child_cat_id"
    :data-attr_str="catInfo.attr_ids"
    :data-min-pri="catInfo.min_price"
    :data-max-pri="catInfo.max_price"
    :data-active-from="activityFrom"
  >
    <!-- 双列瀑布流列表 -->
    <WaterFall
      v-if="waterItems && waterItems.length"
      ref="WaterFall"
      :use-from="useFrom"
      :items="waterItems"
      :init-water-fall="(curPageRefresh || firstIn) && ComponentState.ProductList.isFirstItem && localIndex === -1"
      :ssr="waterFallSsrConfig"
      :local-index="localIndex"
      :dynamic-insert-num="dynamicInsertNum"
      :need-dynamic-insert-animation="needDynamicInsertAnimation"
      @mounted="$emit('waterFallMounted')"
    >
      <template
        slot="default"
        slot-scope="{ item, index, nutareIndex }"
      >
        <AsyncFlow
          v-if="item && item.isFlowData"
          :locals="locals"
          :results="item.results"
          :language="language"
          :list-abt-result="listAbtResult"
          :shein-club-info="sheinClubInfo"
          @doFilt="$emit('doFilt', $event)"
          @clickSearchFilter="handleClickSearchFilter"
        />
        <RecommendProductListItem 
          v-else-if="item.type === 'recommend-for-you'"
          :index="nutareIndex"
          :item="item"
          :hasHandleFilterBar="PageState.hasFilt"
          :product-item-config="productItemConfig"
          :feedback-index="feedbackIndex"
          :landing="landing"
          :language="language"
          :constant-data="constantData"
          :locals="locals"
          :cat-id="catId"
          :shein-club-info="sheinClubInfo"
          :list-abt-result="listAbtResult"
          :feedback-rec-ccc-config="feedbackRecCccConfig"
          :scenes="scenes"
          :use-from="useFrom"
          :report-metrics="reportMetrics"
          @cardMounted="productItemMounted"
          @openQuickAdd="handleOpenQuickAdd"
          @clickItem="handleClickItem"
          @clickMoreBtn="handleFeedbackIndex"
          @longClick="handleFeedbackIndex"
          @clickSearchFilter="handleClickSearchFilter"
          @doFilt="$emit('doFilt', $event)"
          @clickColor="handleClickColor"
          @openBuyBoxDrawer="openBuyBoxDrawer"
          @recPopupMounted="recPopupMounted"
          @operateInRecPopup="$emit('operateInRecPopup')"
        />
        <ProductListItem
          v-else
          :ref="`productListItem_${index}`"
          :index="index"
          :item="item"
          :hasHandleFilterBar="PageState.hasFilt"
          :cat-id="catId"
          :select-id="selectId"
          :product-item-config="productItemConfig"
          :feedback-index="feedbackIndex"
          :landing="landing"
          :language="language"
          :constant-data="constantData"
          :locals="locals"
          :shein-club-info="sheinClubInfo"
          :list-abt-result="listAbtResult"
          :feedback-rec-ccc-config="feedbackRecCccConfig"
          :scenes="scenes"
          :use-from="useFrom"
          :report-metrics="reportMetrics"
          :isFashionStore="hitFashionStoreAbt(item, index)"
          :analysisData="analysisData"
          @cardMounted="productItemMounted"
          @openQuickAdd="handleOpenQuickAdd"
          @clickItem="handleClickItem"
          @clickCheckout="handleClickCheckout"
          @clickMoreBtn="handleFeedbackIndex"
          @longClick="handleFeedbackIndex"
          @clickSearchFilter="handleClickSearchFilter"
          @doFilt="$emit('doFilt', $event)"
          @clickColor="handleClickColor"
          @openBuyBoxDrawer="openBuyBoxDrawer"
          @recPopupMounted="recPopupMounted"
          @operateInRecPopup="$emit('operateInRecPopup')"
        />
      </template>
    </WaterFall>
    <!-- 泛列表新增购物车浮窗icon -->
    <!-- 注意：列表业务场景接入productList.vue组件， 不要重复引入购物车浮窗实例 -->
    <template v-if="hitQuickCart()">
      <QuickCart
        v-if="showCartBag()"
        ref="cartBag"
        :use-from="useFrom"
        :search-feedback-visibility="searchFeedbackVisibility"
        :disabledCartTagTipsShowtime="disabledCartTagTipsShowtime"
        @click-cart="handleClickCartBag"
      />
    </template>
    <template v-else>
      <CartBag
        v-if="showCartBag()"
        ref="cartBag"
        :use-from="useFrom"
        :search-feedback-visibility="searchFeedbackVisibility"
        @click-cart="handleClickCartBag"
      />
    </template>
    <!-- 列表为空数据时，父亲设置了display none，因而需要设置appendtobody -->
    <AgeLimitDialog
      v-if="showAgeLimit"
      :is-search-result-page="isSearchResultPage"
      :store-code="catInfo.store_code"
      :append-to-body="true"
      :visible="showAgeLimit"
      :title="language.SHEIN_KEY_PWA_24889"
      :content="language.SHEIN_KEY_PWA_24890"
      :ok-text="language.SHEIN_KEY_PWA_24891"
      :cancel-text="language.SHEIN_KEY_PWA_24892"
      @expose="onAgeLimitDialogExpose"
      @ok="onAgeLimitDialogOk"
      @cancel="onAgeLimitDialogCancel"
      @close-from-icon="onCloseFormIcon"
    />

    <ClientOnly>
      <!-- Modal Top Bottom -->
      <Coupon
        v-if="showCoupon"
        type="searchModal"
        :pos="couponPos"
      />
      <!-- buy box -->
      <BuyBoxDrawer
        ref="refsBuyBoxDrawer"
      />
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SIMetric } from 'public/src/pages/common/monitor/index.js'
import productItemMixin from 'public/src/pages/components/product/item_v2/js/mixin'
import ClientOnly from 'vue-client-only'
import WaterFall from 'public/src/pages/components/product/WaterFall'
import ProductListItem from './ProductListItem'
import AsyncFlow from './flow/AsyncFlow.vue'
import cardRecommendMixin from '../js/cardRecommendMixin'
import { ageLimitAnalysis } from 'public/src/pages/components/age-limit-dialog'
import { handleClickSearchFilter } from '../js/search.common'
import { debounce, throttle } from '@shein/common-function'
import { getWaterItems, PAGE_NAME_MAP_PAGE_KEY, getListPageInfo } from '../js/utils'
import { SHOW_TYPE_ENUM } from './common/Coupon/common/enum.js'
import { isLogin } from 'public/src/pages/common/utils/index.js' 
import { getCartNumBySkc } from '@/public/src/pages/product_app/util/getCartNum.js'

const daEventExpose = daEventCenter.getExposeInstance()
export default {
  name: 'ProductList',
  components: {
    ClientOnly,
    WaterFall,
    ProductListItem,
    AsyncFlow,
    AgeLimitDialog: () =>
      import(
        /* webpackChunkName: "plv2_AgeLimitDialog" */ 'public/src/pages/components/age-limit-dialog/Index.vue'
      ),
    CartBag: () => import(/* webpackChunkName: "CartBag" */ 'public/src/pages/product_list_v2/components/CartBag/index.vue'),
    QuickCart: () => import(/* webpackChunkName: "QuickCart" */ 'public/src/pages/common/quick_cart/index.vue'),
    Coupon: () => import(/* webpackChunkName: "plv2_FlowItems" */ './common/Coupon/Index.vue'),
    BuyBoxDrawer: () => import(/* webpackChunkName: "plv2_BuyBoxDrawer" */ 'public/src/pages/common/BuyBoxPopup/BuyBoxDrawer.vue'),
    RecommendProductListItem: () => import(/* webpackChunkName: "plv2_RecommendProductListItem" */ 'public/src/pages/product_list_v2/components/RecommendProductListItem.vue'),
  },
  mixins: [productItemMixin, cardRecommendMixin],
  provide(){
    return {
      listAbtResult: this.listAbtResult
    }
  },
  props: {
    noMoreData: Boolean,
    landing: Boolean,
    curPageRefresh: Boolean,
    searchFeedbackVisibility: {
      type: Boolean,
      default: false,
    },
    activityFrom: {
      type: String,
      default: 'goods_list',
    },
    productsOriginData: {
      type: Array,
      default: () => [],
    },
    switchBtn: { // 适用于同一个页面有多个该ProductList实例【搜索页面的上位词商品搜索--hympernymGoods】
      type: Object,
      default: () => ({
        productsOriginOpen: false, // 控制不同数据源商品
        onlyOne: false, // 多个实例只使用一个年龄限制弹层、购物弹框
      }),
    },
    showSkeleton: {
      type: Boolean,
      default: false,
    },
    hypernymProductSum: {
      type: undefined || Number,
      default: undefined
    },
    // 标识该组件是被谁引用了，主要解决cartbag 加车的问题
    useFrom: {
      type: String,
      default: '',
    },
    reportMetrics: {
      type: Object,
      default: () => ({}),
    },
    // 是否需要动态插入卡片出现插入动画
    needDynamicInsertAnimation: {
      type: Boolean,
      default: false,
    },
    addRecommendProductHandle: {
      type: Function,
      default: function() {},
    },
    localIndex: {
      type: [String, Number],
      default: -1,
    },
    showFashionStore: {
      type: Set,
      default: () => new Set()
    },
  },
  data() {
    this.slotCount = 0
    return {
      adultDialogOkClicked: false,
      feedbackIndex: -1,
      showSelect: false,
      selected: {},
      buyBoxGoodsId: '',
      needEmitAddBag: false, // hack code，处理上位词加车
      disabledCartTagTipsShowtime: true,
    }
  },
  computed: {
    ...mapState('productList_v2', [
      'PageState',
    ]),
    // 针对信息流落地页动态插坑个数
    dynamicInsertNum() {
      let { flowInterestReco } = this.listAbtResult
      return +flowInterestReco?.p?.InterestReco || 0
    },
    waterFallSsrConfig() {
      return this.switchBtn.productsOriginOpen
        ? { columns: 2, itemQuantity: this.goodsOne.length }
        : undefined
    },
    isSearchResultPage() {
      const { type } = this.catInfo
      return type === 'search'
    },
    showAgeLimit() {
      const showAgeLimit = (!this.switchBtn.onlyOne &&
        this.productItemConfig.showAdultProductDialog &&
        this.catInfo.hasAdultProduct &&
        !this.showSkeleton &&
        !this.adultDialogOkClicked
      )
      if (showAgeLimit) {
        this.setDialogShowStatus?.({ dialogName: 'ageLimitDialogShow', result: true })
      }
      return showAgeLimit
    },
    // 商品项配置
    productItemConfig() {
      return this.ComponentState?.ProductList?.config || {}
    },
    FlowData() {
      return this.ComponentState?.ProductList?.FlowData || {}
    },
    flowDataV2() {
      return this.ComponentState?.ProductList?.flowDataV2 || {}
    },
    analysisData() {
      return this.ComponentState?.ProductList?.analysisData || {}
    },
    scenes() {
      return this.analysisData.scenes || ''
    },
    cateCoupon() {
      return this.ComponentState?.ProductList?.cateCoupon ?? { Data: {}, Abt: {} }
    },
    // 此处只可能有弹框券 pos=0
    curCoupon() {
      return this.cateCoupon.Data?.[this.couponPos] ?? { couponList: [] }
    },
    firstIn() {
      return this.catInfo?.requestType === 'firstload' || this.catInfo?.requestType === 'switchTab'
    },
    // 当前页面的真实分类id（类目ID）
    catId() {
      return this.catInfo?.cat_id
    },
    // 当前选品列表或者shein-picks(选品id)
    selectId() {
      return this.catInfo?.select_id
    },
    goodsOne() {
      if (this.switchBtn.productsOriginOpen) {
        return this.productsOriginData
      } else {
        return this.goods
      }
    },
    waterItems() {
      return getWaterItems({
        goods: this.goodsOne,
        flowMap: this.flowMap || {},
        useFrom: this.useFrom,
      })
    },
    feedbackRecCccConfig() {
      return this.cccConfig?.feeReco || {}
    },
    showCoupon() {
      if (this.oneClickPayGuideDialogShow) {
        return false
      } else if (this.curCoupon.showType === SHOW_TYPE_ENUM.Modal) { // 弹框券
        return this.curCoupon?.couponList?.length > 0
      } else if ([SHOW_TYPE_ENUM.Up, SHOW_TYPE_ENUM.Bottom].includes(this.curCoupon.showType)) {
        // 搜索优惠券 只有搜索结果页 && 有结果 && pos返回 up/bottom && 通过spa跳转 && 没动过筛选框 && 有券 && 骨架屏结束 && 首次搜索 才显示
        const { isSpaPage, hasHandleFilterBar } = this.PageState
        return (
          this.isSearchResultPage &&
        this.goods.length > 0 &&
        isSpaPage &&
        !hasHandleFilterBar &&
        this.curCoupon?.couponList?.length &&
        !this.PageState.skeletonLoading
        )
      } else {
        return false
      }
    },
    couponPos() {
      if (typeof window === 'undefined') return false
      const { SearchListCoupon } = this.cateCoupon.Abt
      // 列表券的定位
      // const ListCatgCouponPos = ListCatgCoupon?.p?.ListCatgCouponPos
      // 搜索登录券：券的分支以及定位
      // const { SearchSceneCouponPos = false } = SearchSceneCoupon?.p || {}
      // 搜索未登录券：券的定位以及限制
      const { pos: SearchListCouponPos = false } = SearchListCoupon?.p || {}
      const _isLogin = isLogin()
      if (this.isSearchResultPage & !_isLogin) {
        return SearchListCouponPos // 未登录场景 此处可能为弹框/置顶/吸底
      } else {
        return 0 // 列表场景、搜索已登录场景 此处只考虑弹框券
      }
    },
  },
  created() {
    this.quickAddInstance = null

    this.exposeTriggerCartBagTip = false
  },
  // hack code 处理从商详回来，因为keep alive导致悬浮购物车实例还是商详中的
  activated() {
    setTimeout(() => {
      const { cartBag } = this.$refs
      if (cartBag) {
        window._gb_list_cart_ = cartBag
      }
    }, 200)
  },
  mounted() {
    if(!this.useFrom) {
      // 更新没显示出来的列表里的悬浮购物车
      // eslint-disable-next-line no-unused-vars
      appEventCenter.$on('recommendListCartBagAdd', (target) => {
        // this.$refs?.cartBag?.drop?.(target)
        // 因为这个时候利诱点标签的状态还没更新，手动添加个定时延迟在触发
        setTimeout(()=> {
          this.$refs?.cartBag?.updateCartNum()
          this.$refs?.cartBag?.enterPageInitStatus()
          // 销毁监听，防止eventBus带来的内存泄露, 有问题不可加
          // appEventCenter.$off('recommendListCartBagAdd')
        }, 2000)
      })

      this.listViewedTriggerCartBagTip()
      this.listAddTriggerCartBagTip()
    }

    // listViewedTriggerCartBagTip 和 listAddTriggerCartBagTip 触发的时机原本在 useFrom 无值的时候 
    // 有另外一个需求是在 picked-info-land 中也需要触发，所以这里做了个判断
    // if (this.useFrom === 'picked-info-land') {
    //   this.listViewedTriggerCartBagTip()
    //   this.listAddTriggerCartBagTip()
    // }

  },
  destroyed() {
    this.quickAddInstance = null
  },
  methods: {
    ...mapActions('productList_v2', ['setDialogShowStatus']),
    // 是否展示购物车浮窗
    showCartBag() {
      // 上位词存在，只展示上位词中的CartBag， 干掉搜索结果list中的CartBag
      // 两个同时存在，展示搜索结果list中的，干掉上位词list中的CartBag， 特殊处理上位词中的加车逻辑
      // 只存在搜索结果list中的， 干掉上位词list中的CartBag
      // console.log(this.switchBtn.productsOriginOpen, '111', this.productsOriginData, '2222', this.goods?.length, '3333', this.goodsOne.length)
      
      // 这个逻辑是为了解决WEBS-34281， 上位词组件引入了productList 组件，导致购物车浮窗利诱点相关功能异常
      // 两个同时存在，展示搜索结果list中的(搜索结果list会先触发渲染)
      if(this.switchBtn.productsOriginOpen && (this.productsOriginData?.length > 0) && (this.goods?.length > 0)) {
        this.needEmitAddBag = true // 标识这个时候两个同时存在，为了上位词加车用
        return false
      }
      // 哪个存在渲染哪个
      return this.productItemConfig.showCartBag && !this.constantData?.IS_RW && (this.goodsOne.length > 0)
    },
    getExposeType() {
      const { child_cat_id } = this.catInfo
      const expose_type = this.isSearchResultPage ? 4 : child_cat_id ? 2 : 1
      return expose_type
    },
    onAgeLimitDialogExpose() {
      const expose_type = this.getExposeType()
      ageLimitAnalysis.exposePopup({ expose_type })
    },
    onAgeLimitDialogOk() {
      this.adultDialogOkClicked = true
      const expose_type = this.getExposeType()
      const button_type = 1
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },
    onAgeLimitDialogCancel() {
      const expose_type = this.getExposeType()
      const button_type = 2
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },
    onCloseFormIcon() {
      const expose_type = this.getExposeType()
      const button_type = 3
      ageLimitAnalysis.clickPopup({ expose_type, button_type })
    },
    // 分步式搜索的点击回调, 锦囊搜索参考此交互 + PicTopNav2Query实验参考此交互
    handleClickSearchFilter,
    handleFeedbackIndex({ index, isShow }) {
      if(!isShow) {
        return 
      }
      this.hideFeedbackRecPopup() // cardRecommendMixin 中的方法
      this.feedbackIndex = index
    },
    handleClickItem(payload) {
      this.$emit('clickGoods', payload)
    },
    openBuyBoxDrawer ({ goods_id, cat_id }) {
      const { pageName } = this.catInfo 
      const { searchgoodsCard, listgoodsCard } = this.listAbtResult
      const displayWindowAbtBranch = pageName === 'page_search' ? searchgoodsCard?.p?.searchGoods : listgoodsCard?.p?.listGoods
      if (this.buyBoxGoodsId !== goods_id) this.$refs?.refsBuyBoxDrawer?.refreshData?.()
      this.buyBoxGoodsId = goods_id

      this.$refs?.refsBuyBoxDrawer?.open?.({
        analysisConfig: { goodsId: goods_id },
        isPaidUser: this?.sheinClubInfo?.isPaid,
        requestParams: {
          goods_id,
          cat_id,
          pageKey: PAGE_NAME_MAP_PAGE_KEY[pageName] || '',
          cccParams: {
            displayWindowAbtBranch // 开启新商卡橱窗ABT
          },
        },
      },)
    },
    // 打开快速加车
    async handleOpenQuickAdd({ item, index, target, isRecommend = true, imgRatio, activityFrom }) {
      const updateOneClickOrder = throttle({
        func: (params) => {
          this.$emit('oneClickPayComplete', params)
        },
        wait: 3000,
        options: {
          trailing: false
        }
      })

      const { goods_id, mall_code, goods_img, mobileVerticalView } = item
      const hypernymProductSum  = typeof(this.hypernymProductSum) === 'number' ? { hypernymProductSum: this.hypernymProductSum } : null
      const { src_module, src_identifier, src_tab_page_id, url_from } = this.catInfo
      this.quickAddInstance = await this.$quickAdd.open({
        index,
        goods_id,
        lockmall: !!this.catInfo.lockMall,
        mallCode: mall_code,
        mainImg: goods_img,
        imgRatio,
        showBestDealLabel: true,
        showFollowBeltByOrigin: true,
        showEstimatedPrice: !item.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice,
        showFeedbackRec: true, // FR-14861和商详约定
        needCartTagTipsUpdate: false, // TR-17838增加, 不需要快加车内部去触发利诱相关的update方法
        pageListType: this.catInfo.pageListType,
        analysisConfig: {
          code: 'productList',
          sourceTarget: target || null,
          sa: {
            activity_from: activityFrom || this.activityFrom,
            mobileVerticalView,
          },
          ...hypernymProductSum
        },
        detailUrlExtendParam: {
          src_module: url_from ? 'ads' : src_module,
          src_identifier: url_from ? `uf=${url_from}` : src_identifier,
          src_tab_page_id,
        },
        clickCallBack: {
          electSizeDefault: (params) => {
            // 非用户选择的尺寸默认选中
            this.changeSizeAttrs(params)
          },
          // 尺寸选择
          selectSize: (params) => {
            this.changeSizeAttrs(params)
          },
          isActive: (active, goodsId, clickDetails, addBagStatus, addWishStatus) => {
            // cardRecommendMixin方法
            this.handleFeedbackRecInfo({
              active,
              clickDetails,
              goodsId,
              addBagStatus,
              addWishStatus,
              isRecommend,
            })
            if (!active) {
              updateOneClickOrder({})
              // 针对是否执行点后推商品 （信息流）
              let { flowInterestReco } = this.listAbtResult
              const { isPickInfoPage } = getListPageInfo(this.catInfo)

              let isNeedAddRecGood = isPickInfoPage && flowInterestReco?.p?.InterestReco && item.type !== 'recommend-for-you'
              if(isNeedAddRecGood) {
                // 信息流落地页 执行插入推荐
                this.addRecommendProductHandle(false, true, {
                  clickDetails,
                  goodsId,
                  addBagStatus,
                  addWishStatus,
                })
              }
            }
          },
          detail: (goodsId, addBagStatus, addWishStatus) => {
            if (addBagStatus || addWishStatus) {
              this.useGoodId.push(goodsId)
            }
          },
          handleOneClickPayComplete: (params) => {
            updateOneClickOrder(params)
          },
          clickClose: () => {
            this.$emit('quickAddSuccess', { goods_sn: item.goods_sn, index, checkoutNum: item.checkoutNum, addSuccess: false })
          },
          complete: (params) => {
            const { code } = params?.data || {}

            if (Number(code) === 0) {
              this.$emit('quickAddSuccess', { goods_sn: item.goods_sn, index, checkoutNum: item.checkoutNum, addSuccess: true })
            }
          },
        },
      })

      if (this.quickAddInstance) {
        this.quickAddInstance?.$on('addToBagSuccess', this.debounceIt)
      }
    },
    // 给上位词用
    injectsDropCart(target) {
      this.$refs?.cartBag?.drop?.(target)
      this.quickAddInstance?.$off?.('addToBagSuccess')
    },
    // 处理重复注册addToBagSuccess， 导致的问题
    debounceIt: debounce({
      func: function ({ target }) {
        // 如果是反馈半屏列表里的cartbag public/src/pages/product_list_v2/components/RecommendDrawer.vue eventBus消息外层
        if(this.useFrom === 'RecommendDrawer') {
          appEventCenter.$emit('recommendListCartBagAdd', { target })
        }
        // 如果是上位词存在，少抛出去，走外层productList中的debounceIt
        if((this.switchBtn.productsOriginOpen && this.needEmitAddBag) || !this.$refs?.cartBag) {
          this.$emit('dropCart', target)
        } else {
          this.$refs?.cartBag?.drop?.(target)
        }
        this.quickAddInstance.$off('addToBagSuccess')
      },
      wait: 20,
    }),
    setAnalysisData(requestType) {
      if (requestType !== 'nexpage') {
        const listEl = this.$refs.productList
        listEl.setAttribute('da-expose-code', this.analysisData.exposeCode)
        listEl.setAttribute('data-poskey', this.analysisData.scenes)
        listEl.setAttribute('data-traceid', this.analysisData.traceId || '')
        listEl.setAttribute('data-request_ext', this.analysisData.request_ext || '')
        listEl.setAttribute('data-dimension', this.analysisData.saDimension || '')
      }
      if (!this.switchBtn.productsOriginOpen) {
        const keymap = this.appendGoods.reduce((curr, next) => {
          curr[next.goods_id] = true
          return curr
        }, {})
        this.goods.forEach((item, index) => {
          if (keymap[item.goods_id]) {
            // cardRecommendMixin方法
            // 这里通过两层 ref 取到最终的 Item 组件实例
            const itemInstance = this.getProductListItemInstance(index)?.$refs?.[`goodsItem_${index}`]
            itemInstance?.setElAttr()
            // 店铺场景插坑index
            if (this.useFrom === 'store') {
              if (itemInstance) {
                // 获取真实的index信息
                itemInstance.$el?.setAttribute?.('data-store-index', index - this.slotCount)
              } else if (this.flowMap?.[index]) {
                this.slotCount++
              }
            }
          }
        })
      } else {
        // 处理 productsOriginData 的productItem attr
        this.productsOriginData.forEach((item, index) => {
          if (!item.exposed) {
            const itemInstance = this.getProductListItemInstance(index)?.$refs?.[`goodsItem_${index}`]
            itemInstance?.setElAttr()
            item.exposed = true
          }
        })
      }

      return `${this.productItemConfig.itemDAEventExposeId}|2-10-2|1-5-1-5|1-36-1-15|1-5-1-26`
    },
    getSearchPriority(item) {
      return item?.ext?.recall_mark?.split('_')?.[1]
    },
    // 快加车尺寸切换
    changeSizeAttrs({ curSelectAttr }) {
      if (curSelectAttr.attr_value_name) {
        daEventCenter.triggerNotice({
          daId: '1-6-1-33',
          target: {
            dataset: {
              attr_id: curSelectAttr.attr_id,
              attr_value_id: curSelectAttr.attr_value_id || '',
              attr_value: curSelectAttr.attr_value_name,
              location: 'popup',
            },
          },
        })
      }
    },
    // 点击切换颜色
    handleClickColor() {
      // 关闭相关推荐浮层
      this.hideFeedbackRecPopup() // cardRecommendMixin方法
    },
    recPopupMounted() {
      this.showFeedbackRecPopup() // cardRecommendMixin方法
    },
    hitQuickCart() {
      const isStoreSearchPage = this.catInfo?.search_type === 'store'
      const isListSearchPage = this.catInfo?.type === 'search'
      const isListPage = this.catInfo?.type !== 'store'

      if (isStoreSearchPage) {
        return this.listAbtResult.StoreCartPopover?.p?.StoreCartPopover
      }

      if (isListSearchPage) {
        return this.listAbtResult.SearchCartPopover?.p?.SearchCartPopover
      }

      if (isListPage) {
        return this.listAbtResult.ListCartPopover?.p?.ListCartPopover
      }

      return null
    },
    handleCartPopoverAbt() {
      const abtValue = this.hitQuickCart() || ''

      const viewedList = abtValue.includes('view') ? 'view' : ''
      const addList = abtValue.includes('add') ? 'add' : ''
      const exposeList = abtValue.includes('expose') ? 'expose' : ''

      return [viewedList, addList, exposeList].filter(Boolean)
    },
    // 悬浮购物车下单引导
    listViewedTriggerCartBagTip() {

      if (!this.handleCartPopoverAbt().includes('view')) return

      window._gb_cart_tag_tips_?.showtime()
    },
    listAddTriggerCartBagTip() {
      if (!this.handleCartPopoverAbt().includes('add')) return

      this.disabledCartTagTipsShowtime = false
    },
    listExposeTriggerCartBagTip(currentTargetIndex) {
      if (!this.handleCartPopoverAbt().includes('expose')) return

      const { ListCartPopover } = this.listAbtResult || {}
      const listCartStr = ListCartPopover?.p?.ListCartPopover || ''

      const exposeNumReg = /expose=(\d+)/
      const matchExposeNum = listCartStr.match(exposeNumReg)

      if (matchExposeNum) {
        const exposeNum = matchExposeNum[1] <= 10 ? 10 : Number(matchExposeNum[1])

        if (currentTargetIndex >= exposeNum && !this.exposeTriggerCartBagTip) {
          window._gb_cart_tag_tips_?.showtime()
          this.exposeTriggerCartBagTip = true
        }
      }

    },
    async handleClickCheckout({ goods_sn }) {
      const checkoutedInfo = await getCartNumBySkc([ goods_sn ]) || {}
      const cartId = checkoutedInfo[goods_sn]?.list?.[0]?.id

      if (cartId) {
        window._gb_app_.$router.push({
          name: 'cart',
          params: {
            cartId
          }
        })
      }
    },
    setAddCartType(index, type) {
      const itemInstance = this.getProductListItemInstance(index)?.$refs?.[`goodsItem_${index}`]
      itemInstance?.handleSetAddCartType(type)
    },
    // 处理动态插坑后重新渲染问题
    productItemMounted(componetInstance) {
      if(this.constantData?.IS_RW) {
        return 
      }
      if(!componetInstance?.$el?.dataset?.id && this.needDynamicInsertAnimation) {
        componetInstance?.setElAttr?.() // 设置埋点属性在dom上
      }
      // 针对动态插坑的推荐商卡
      if(componetInstance?.item?.type === 'recommend-for-you') {
        // 固定开头（infoflowRealtime）+scene_id（）
        componetInstance?.$el.setAttribute('data-recommend-info', 'infoflowRealtime_252')
        this.$nextTick(()=> {
          setTimeout(() => {
            daEventExpose.subscribe({
              keycode: `picked_list_exposeCode\`2-3-2`,
              type: 'list',
            })
          }, 200)
        })
      }
    },
    // 点击悬浮购物车
    handleClickCartBag() {
      // 点击悬浮购物车监控指标
      this.reportMetricCount({
        metricName: 'suspension_cart_click_total',
        tags: {
          page: this.catInfo.pageName,
        },
        message: 'Number of click cart bag',
      })
    },
    // 收集端上监控指标
    reportMetricCount({ metricName, tags, message }) {
      SIMetric.metricCount({
        metric_name: metricName,  // 指标名称
        tags, // 指标维度
        message // 日志信息
      })
    },
    hitFashionStoreAbt(item, index) {
      const { listtrendshopEntry } = this.listAbtResult || {}
      const firstFashionStoreAfterNum = parseInt(listtrendshopEntry?.p?.listtrendShop)
      // const firstFashionStoreAfterNum = 0

      if (isNaN(firstFashionStoreAfterNum)) return false

      const isModStore = item.storeInfo && item.storeInfo.isModStore && item.storeInfo.title
      const itemIndex = index + 1

      // 从1开始render，说明整个列表已经重新render了，就直接重新计算时尚店铺是否出现过
      if (itemIndex === 1) {
        this.showFashionStore.clear()
      }

      if ((itemIndex > firstFashionStoreAfterNum) && isModStore && this.showFashionStore.size < 1) {
        this.showFashionStore.add(item.goods_id)
        return true
      } else {

        if (this.showFashionStore.has(item.goods_id)) {
          return true
        }
        return false
      }
    },
  },
}
</script>

<style lang="less">
.product-list-v2 {
  &__list {
    --item-column-width: 4.52rem;
    padding: 0 0.32rem;
    .product-item-ctn {
      margin-bottom: 0.4267rem;
    }
    .product-card {
      margin-bottom: 0.16rem;
    }
    .flow-item-ctn {
      width: var(--item-column-width);
      border-radius: var(--item-outer-radius, 0);
      overflow: hidden;
      margin-bottom: var(--new-card-flow-cate-margin-bottom, 0.4267rem);
    }
  }

  &__list.actived-newproductcard {
    --item-column-width: 4.76rem;
    --item-outer-radius: 4px;
    --item-inner-radius: 2px;
    --new-card-flow-cate-bg: #fff;
    --new-card-flow-cate-margin-bottom: 0.16rem;

    padding: 0 0.16rem;
    background-image: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    background-size: 100% 160px;
    background-repeat: no-repeat;
  }

  &__list-lazy-holder {
    height: 6.02rem;
  }
}
</style>
